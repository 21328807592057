import React from "react";
import Seeds from "./images/seeds.png";
import Fertilizers from "./images/fertilizer.png";
import Pesticides from "./images/pesticides.png";
import BioFertilizers from "./images/bio_fertilizers.png";
import Tools from "./images/tools_and_machinery.png";
import './About.css'

const Products = () => {
    return (
        <div className='about' id="products">
            <div className='container'>
                <div className='col-2'>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p className="name-label">We, deals with all these categories of agriculture products</p>
                    <span className='start-line'></span> <br /><br />
                    <p></p>
                    <div >
                        <img src={Seeds} alt="Seeds" width={200} height={200} className="image-space" />
                        <img src={Fertilizers} alt="Fertilizers" width={200} height={200} className="image-space" /><br /><br /><br />
                        <img src={Pesticides} alt="Pesticides" width={200} height={200} className="image-space" />
                        <img src={BioFertilizers} alt="BioFertilizers" width={200} height={200} className="image-space" /><br /><br /><br />
                        <img src={Tools} alt="Tools" width={200} height={200} className="image-space" />
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
};
export default Products;