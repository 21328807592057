import React from "react";

const Contact = () => {
  return (
    <div className='about' id="contact">
      <div className='container'>
        <div className='col-2'>
          <p></p>
          <p></p>
          <p></p>
          <p className="contact-label">Want to build the bussiness empire with us?</p>
          <span className='start-line'></span>
          <p className="contact-label-2"> Whether you just want to give a try of our tech plateform or are looking to start a bussiness, feel free to email & we'll quickly be in touch!</p>
          <p className="font-weight-1">Don't wait any longer, let us solve your bussiness problems!</p>
          <div >
            <div>
              <button className="button">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
