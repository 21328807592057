import React from "react";

const Career = () => {
  return (
    <div className='about' id="career">
      <div className='container'>
        <div className='col-2'>
          <p></p>
          <p></p>
          <p></p>
          <p className="contact-label-3">Do You want to work in the technologies of the future?</p>
            <span className='start-line'></span>
          <p className="contact-label-2">We have the unique distinction. Join a team of serial entrepreneurs who have successfully blended Services and Products for many years with multiple exits. Be a part of a winning team with a startup culture and immense learning opportunities to collaboratively spearhead the next generation technologies!</p>
          <p className="font-weight-1">If you are a market expert or know someone who is, Do share you details to work with us.!</p>
          <div >
            <div>
              <label>career@mirseed.com</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;