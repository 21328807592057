import React from 'react'
import RootLayout from './components/Root'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import NotFoundScreen from "./components/404";
import Privacy from './components/Privacy'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <NotFoundScreen />,
    children: [
      {
        path: '/privacy',
        element: <Privacy />,
      }
    ]
  }])

function App() {
  return (

    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
