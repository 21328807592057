import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero'>
            <div className='content'>
                <p></p>
                <p></p>
                <p>We are committed</p>
                <p>We, delivers your agriculture needs at your retail store in eye blinking</p><br />
                <p>Let us deliver your all your Agriculture needs at your retail store as per your convenience. We help you to focus on your retail</p>
                <br />
                <a href='https://play.google.com/store/apps/details?id=com.mirseed.retailer&pli=1' target='_blank' rel="noreferrer">
                    <button className='button'>Download Our App </button>
                </a>
            </div>
        </div>
    )
}

export default Hero
