import Navbar from "./Navbar";
import Contact from "./Contact";
import Footer from "./Footer";
import About from "./About";
import Products from "./Products";
import Hero from "./Hero";
import Career from "./Career"
import Privacy from "./Privacy";
import { Outlet } from "react-router";


function RootLayout() {
    return (
        <>
            <div>
                <Navbar />
                <Hero />
                <Products />
                <Contact />
                <Career />
                <About />
                <Privacy/>
                <Footer />
                <Outlet />
            </div>
        </>
    );
}

export default RootLayout;
