import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <ul>
                    <li className='nav-item'>
                        <a href='/'>Home</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#products'>Our Products</a>
                    </li>

                    <li className='nav-item'>
                        <a href='#contact' >Contact</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#career'>Career</a>
                    </li>

                    <li className='nav-item'>
                        <a href='#about'>About Us</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#privacy'>Privacy & Data</a>
                    </li>
                </ul>
                <div className='bottom'>
                    <span className='line'></span>
                    <p>© Copyright 2023 Mirseed Wholesale LLP</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
