import React from 'react'
import john from './images/john-doe.png'
import './About.css'

const About = () => {
    return (
        <div className='about' id='about'>
            <div className='container'>
                <img src={john} alt='john' />
                <div className='col-2'>
                    <h2>About</h2>
                    <span className='start-line-2'></span>
                    <p>We are driven by our Vision… “To be integral to the success of our clients and our people, to be their most valued partner and create a more secure, trustworthy and connected world.” And our Mission. “To foster a culture of excellence and innovation making customer success our highest priority. ” Our CORE value is transparency, both, to our customers and our people.</p>
                    <p>Join the revolution in technology</p>
                    <p>If you have a business problem, We have the tech solution</p>
                </div>
            </div>
        </div>
    )
}

export default About
