import React from 'react';
import { Center, Flex, Heading } from "@chakra-ui/react";
import Navbar from './Navbar';

export default function NotFoundScreen() {
  return (
    <>
      <Navbar />
      <Flex
        width={"100%"}
        height="96.5vh"
        justifyContent={"center"}
        alignItems={"center"}
        direction='column'
      >
        <Center>
          <Heading>Ooooppppsss....</Heading>
        </Center>
        <Center>
          <Heading size="md" mt={5}>
            Not found the page you are looking for...
          </Heading>
        </Center>
      </Flex>
    </>
  );
}
